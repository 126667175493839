import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Columns,
  Text,
  ContentBlock,
  Stack,
  Divider,
} from "@64labs/bowline-design-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery } from "gatsby"
import PageFeature from "../components/page-feature"
import "./about.css"
import ProfileCard from "../components/about/profile-card"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query aboutFeature {
      file(relativePath: { eq: "img-about.png" }) {
        childImageSharp {
          fluid(maxWidth: 972, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      leadership: contentfulList(title: { eq: "Leadership Order" }) {
        title
        item {
          ... on ContentfulLeadership {
            name
            title
            linkedInUrl
            bio {
              bio
            }
          }
        }
      }
    }
  `)

  const leadership = data && data.leadership && data.leadership.item
  return (
    <Layout showPageHeader={true} pageHeaderText="About 64labs">
      <SEO
        title="About Us"
        description="64labs partners with big and small companies to help get the best and most cost effective solution to web challenges."
      />
      <PageFeature
        fluid={data.file.childImageSharp.fluid}
        title="We can help you succeed"
        buttonText="learn about our services"
        buttonLink="/services"
      >
        <Text className="page-feature__paragraph">
          You don’t want a solution that will need to be rebuilt every six
          months. You need a partner who can guide you now and keep you up to
          date forever. We can implement proven, trusted mobile technologies as
          well as full responsive solutions used by major companies worldwide.
          We choose the tool to suit the challenge you face.
        </Text>
      </PageFeature>

      <Box
        background="subtleLight"
        paddingY="large"
        position="relative"
        overflow="hidden"
      >
        <Box
          size="xlarge"
          background="white"
          position="absolute"
          style={{
            top: -8,
            left: "50%",
            transform: "rotate(45deg) translateX(-50%)",
          }}
        />
        <ContentBlock width="large" paddingTop="small">
          <Box>
            <Stack space="large">
              <Stack space="small">
                <Text as="h2" heading size="large" tone="brandAccent">
                  We belong to you
                </Text>
                <Text>
                  You’ve taken a lot of calls from vendors over the past couple
                  of years. We have worked in mobile long enough to know the
                  strengths and weaknesses of every vendor and every solution.
                  Our perspective and experience with all the major players in
                  mobile optimization can save you months of headaches and tens
                  of thousands of dollars. We know our way around Websphere,
                  ATG, Magento, Hybris, Demandware and many other ecommerce
                  systems. Our knowledge of the risks and rewards of responsive
                  design can bring you to the cutting edge in mobile and
                  desktop.{" "}
                  <strong>
                    We want to use what we know to help you succeed.
                  </strong>
                </Text>
              </Stack>

              <Divider style={{ borderTop: "1px solid #ddd" }} />

              <Columns cols={[1, 1, 3]} gap="large">
                <Stack space="small">
                  <Text as="h3" tone="brand" heading>
                    We are partners
                  </Text>
                  <Text>
                    We succeed when you succeed, and that means getting you the
                    right solution at the best possible price. We believe in
                    building partnerships, not selling products. We will stick
                    around to maintain and improve our work long after launch.
                  </Text>
                </Stack>
                <Stack space="small">
                  <Text as="h3" tone="brand" heading>
                    We are experienced
                  </Text>
                  <Text>
                    Our teams have worked on projects large and small in mobile
                    for the past two years. We know the challenges and pitfalls.
                    Learning to do mobile well is hard. Keeping ahead of the
                    game is hard. You want a partner who has lived, eaten and
                    breathed this stuff for years, not days.
                  </Text>
                </Stack>
                <Stack space="small">
                  <Text as="h3" tone="brand" heading>
                    We are intelligent
                  </Text>
                  <Text>
                    The first step to solving a problem is to understand it. Our
                    team learns about your business, your technologies and how
                    you compete before they ever recommend a solution. Our
                    process is intelligent too; agile, transparent,
                    collaborative.
                  </Text>
                </Stack>
              </Columns>
            </Stack>
          </Box>
        </ContentBlock>
      </Box>

      <ContentBlock width="large" paddingY="large">
        <Stack space="medium">
          <Text as="h2" heading tone="brandAccent" size="large">
            Our leadership team
          </Text>

          <Columns cols={[1, 1, 4]} rowGap="large">
            {leadership &&
              leadership.map((leader, idx) => {
                return (
                  <>
                    <ProfileCard
                      key={idx}
                      name={leader.name}
                      title={leader.title}
                      linkedInHref={leader.linkedInUrl}
                    >
                      <Text size="small">{leader.bio && leader.bio.bio}</Text>
                    </ProfileCard>
                    {idx !== leadership.length - 1 && (
                      <Divider display={["block", "block", "none"]} />
                    )}
                  </>
                )
              })}
          </Columns>
        </Stack>
      </ContentBlock>
    </Layout>
  )
}

export default AboutPage
